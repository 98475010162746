var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-cream py-5 px-10 rounded-2xl text-fonts-primary"},[_c('h2',{staticClass:"heading-l-500 mb-6"},[_vm._v("Create a free account and find your pro today!")]),_c('ValidationObserver',{ref:"userForm",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"grid grid-cols-2 gap-x-4 gap-y-4",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('ValidationProvider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"First Name","x-padding":"px-5","placeholder":"John","errors":errors},model:{value:(_vm.form.user.first_name),callback:function ($$v) {_vm.$set(_vm.form.user, "first_name", $$v)},expression:"form.user.first_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Last Name","x-padding":"px-5","placeholder":"Smith","errors":errors},model:{value:(_vm.form.user.last_name),callback:function ($$v) {_vm.$set(_vm.form.user, "last_name", $$v)},expression:"form.user.last_name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"disabled":_vm.form.isOauthRegistration,"label":"Email","x-padding":"px-5","placeholder":"example@email.com","errors":errors},on:{"onBlur":function($event){return _vm.emailCheck(errors)}},model:{value:(_vm.form.user.email),callback:function ($$v) {_vm.$set(_vm.form.user, "email", $$v)},expression:"form.user.email"}},[(_vm.checkingEmail)?_c('template',{slot:"rightIcon"},[_c('loading-icon',{staticClass:"h-2 my-1"})],1):(_vm.check != null)?_c('template',{slot:"rightIcon"},[(!_vm.check || errors.length)?_c('XIcon',{staticClass:"text-red-600",attrs:{"strokeWidth":"2"}}):_c('CheckIcon')],1):_vm._e()],2)]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"name":"Organization name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Organization name","x-padding":"px-5","errors":errors},model:{value:(_vm.church.name),callback:function ($$v) {_vm.$set(_vm.church, "name", $$v)},expression:"church.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Phone Number","x-padding":"px-5","errors":errors,"phoneInput":true},on:{"phone":function($event){return _vm.setPhone($event)}}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"name":"Password","rules":"required|min:8","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"type":"password","label":"Password","placeholder":"Choose a password","x-padding":"px-5","errors":errors},model:{value:(_vm.form.user.password),callback:function ($$v) {_vm.$set(_vm.form.user, "password", $$v)},expression:"form.user.password"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"name":"Password confirmation","rules":"required|min:8|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"type":"password","label":"Confirm password","placeholder":"Repeat your password","x-padding":"px-5","errors":errors},model:{value:(_vm.form.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form.user, "password_confirmation", $$v)},expression:"form.user.password_confirmation"}})]}}],null,true)}),_c('div',{staticClass:"col-span-2"},[_c('ButtonIcon',{staticClass:"w-full mt-4",attrs:{"type":"submit","text":"Sign Up","disabled":invalid || !_vm.phoneIsValid || _vm.loading,"loading":_vm.loading},scopedSlots:_vm._u([{key:"leftIcon",fn:function(){return [_c('ArrowIcon',{staticClass:"mr-2"})]},proxy:true}],null,true)})],1)],1)]}}])}),_c('div',{staticClass:"button-label-m-500 mt-5 flex justify-center select-none"},[_vm._v(" Are you a Pro? "),_c('span',{staticClass:"click-here",on:{"click":_vm.onAreYouPro}},[_vm._v(" Click here ")]),_vm._v(" to create a free account. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }