<template>
  <div class="bg-cream py-5 px-10 rounded-2xl text-fonts-primary">
    <h2 class="heading-l-500 mb-6">Create a free account and find your pro today!</h2>
    <ValidationObserver
      v-slot="{ invalid }"
      rules="required"
      ref="userForm"
    >
      <form
        @submit.prevent="onSubmit"
        class="grid grid-cols-2 gap-x-4 gap-y-4"
      >
        <ValidationProvider
          name="First name"
          rules="required"
          v-slot="{ errors }"
        >
          <TextInput
            v-model="form.user.first_name"
            label="First Name"
            x-padding="px-5"
            placeholder="John"
            :errors="errors"
          />
        </ValidationProvider>

        <ValidationProvider
          name="Last name"
          rules="required"
          v-slot="{ errors }"
        >
          <TextInput
            v-model="form.user.last_name"
            label="Last Name"
            x-padding="px-5"
            placeholder="Smith"
            :errors="errors"
          />
        </ValidationProvider>

        <ValidationProvider
          name="Email"
          vid="email"
          rules="required|email"
          v-slot="{ errors }"
          class="col-span-2"
        >
          <TextInput
            :disabled="form.isOauthRegistration"
            v-model="form.user.email"
            label="Email"
            x-padding="px-5"
            placeholder="example@email.com"
            :errors="errors"
            @onBlur="emailCheck(errors)"
          >
            <template slot="rightIcon" v-if="checkingEmail">
              <loading-icon class="h-2 my-1" />
            </template>
            <template slot="rightIcon" v-else-if="check != null">
              <XIcon
                class="text-red-600"
                strokeWidth="2"
                v-if="!check || errors.length"
              />
              <CheckIcon v-else />
            </template>
          </TextInput>
        </ValidationProvider>

        <ValidationProvider
          name="Organization name"
          rules="required"
          v-slot="{ errors }"
          class="col-span-2"
        >
          <TextInput
            v-model="church.name"
            label="Organization name"
            x-padding="px-5"
            :errors="errors"
          />
        </ValidationProvider>

        <ValidationProvider
          name="Phone Number"
          rules="required"
          v-slot="{ errors }"
          class="col-span-2"
        >
          <TextInput
            label="Phone Number"
            x-padding="px-5"
            :errors="errors"
            :phoneInput="true"
            @phone="setPhone($event)"
          />
        </ValidationProvider>

        <ValidationProvider
          name="Password"
          rules="required|min:8"
          vid="password"
          v-slot="{ errors }"
          class="col-span-2"
        >
          <TextInput
            type="password"
            v-model="form.user.password"
            label="Password"
            placeholder="Choose a password"
            x-padding="px-5"
            :errors="errors"
          />
        </ValidationProvider>

        <ValidationProvider
          name="Password confirmation"
          rules="required|min:8|confirmed:password"
          v-slot="{ errors }"
          class="col-span-2"
        >
          <TextInput
            type="password"
            v-model="form.user.password_confirmation"
            label="Confirm password"
            placeholder="Repeat your password"
            x-padding="px-5"
            :errors="errors"
          />
        </ValidationProvider>

        <div class="col-span-2">
          <ButtonIcon
            type="submit"
            text="Sign Up"
            class="w-full mt-4"
            :disabled="invalid || !phoneIsValid || loading"
            :loading="loading"
          >
            <template #leftIcon> <ArrowIcon class="mr-2" /> </template>
          </ButtonIcon>
        </div>
      </form>
    </ValidationObserver>
    <div class="button-label-m-500 mt-5 flex justify-center select-none">
      Are you a Pro?
      <span class="click-here" @click="onAreYouPro">
        Click here
      </span>
      to create a free account.
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import TextInput from "@/components/shared/inputs/TextInput";
import CheckIcon from "@/components/shared/svg/CheckIcon";
import XIcon from "@/components/shared/svg/XIcon";
import ArrowIcon from "@/components/shared/svg/ArrowIcon";

export default {
  name: "SignupModal",
  components: { ButtonIcon, TextInput, CheckIcon, XIcon, ArrowIcon },

  data() {
    return {
      checkingEmail: false,
      loading: false,
      phone: null,
      phoneIsValid: false,
      church: {
        phone: "",
        phone_country_code: "",
        name: null
      },
      templateWasSelected: false,
    };
  },

  computed: {
    ...mapState({
      form: state => state.auth.form,
      check: state => state.auth.checkEmail,
      currentTemplate: state => state.jobs.currentTemplate,
      modalVisible: state => state.general.modalVisible,
    })
  },

  mounted() {
    this.templateWasSelected = !!this.currentTemplate;
    this.resetForm();
    this.setType("church");
  },

  watch: {
    modalVisible(visible) {
      if (!visible && !this.templateWasSelected) {
        this.setCurrentTemplate(null);
      }
    }
  },

  methods: {
    ...mapMutations({
      setType: "auth/setType",
      resetForm: "auth/resetForm",
      increaseStep: 'auth/increaseStep',
      hideModal: 'general/hideModal',
      setCurrentTemplate: 'jobs/setCurrentTemplate',
    }),

    ...mapActions({
      register: "auth/register",
      checkEmail: "auth/checkEmail"
    }),

    async onSubmit() {
      this.loading = true;
      this.form.church = this.church;

      this.register(this.form)
        .then(response => {
          if (response) {
            window.fbq("trackCustom", "signup-church");
            if (this.currentTemplate) {
              this.$router.push({name: 'PostJob', params: { startFrom2: true }});
            } else {
              this.$router.push({ name: "Home" });
            }
            this.hideModal();
          }
        })
        .catch(errors => {
          console.log(errors);
          this.$refs.userForm.setErrors(errors.errors);
        })
        .finally(() => (this.loading = false));
    },

    setPhone(phoneObject) {
      this.phoneIsValid = phoneObject.valid;
      this.church.phone_country_code = phoneObject.countryCode;
      this.church.phone = phoneObject.number;
    },

    emailCheck(errors) {
      if (!errors.length) {
        this.checkingEmail = true;
        this.checkEmail({ email: this.form.user.email })
          .then(response => {
            if (!response) {
              this.$refs.userForm.setErrors({ email: "Email already taken" });
            }
          })
          .finally(() => (this.checkingEmail = false));
      }
      if (this.form.user.email == "") {
        this.setCheckEmail(null);
      }
    },

    onAreYouPro() {
      this.resetForm();
      this.setType("pro");
      this.increaseStep();
      this.$router.push({name: 'UserForm'})
      this.hideModal();
    },
  }
};
</script>

<style scoped>
.click-here {
  @apply mx-1 text-center text-secondary-dark hover:text-secondary-main cursor-pointer;
}
</style>
